.blog-sec{
    display: flex;
    flex-direction: column;
    max-width: 1565px;
    margin: 0px auto;
    padding: 100px;
}
.blog-sec .blog-heading{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 50px;
}
.blog-sec .blog-heading h1{
    margin: 0px;
}
.blog-sec .blog-heading .category-dropdown{
    display: flex;
    flex-direction: column;
    min-width: 300px;
}
.blog-sec .blog-heading .category-dropdown .nice-select {
    font-size: 16px;
}
.blog-sec .blog-banner{
    max-height: 500px !important;
    height: 100%;
    width: 100%;
    position: relative;
}
.blog-sec .blog-banner .general{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--ltn__secondary-color) ;
    color: white;
    z-index: 2;
}
.blog-sec .blog-banner img{
    max-height: 500px !important;
    height: 100%;
    width: 100%;
}
.blog-sec .blog-banner .content{

    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;

    max-width: 560px;
    z-index: 2;

}
.blog-sec .blog-banner .content .blog-title{

    font-size: clamp(28px,2.67559vw + 7.45151px,44px);
    color: white;
line-height: 100%;

} 
.blog-sec .blog-banner .content span{
    color: #cccccc;
}
.blog-sec .blog-banner .content p{
    font-size:clamp(15px,0.50083vw + 11.15359px,18px);
    color: #E8E8E8;
    line-height: 120%;
    margin-bottom: 0;
}
.blog-sec .blog-banner .blogcard-layer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.262);

}
.blog-sec .blog-cards{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px;
}
.blog-sec .blog-cards .blog-card{
    max-height: 500px !important;
    height: 100%;
    width: 100%;
    position: relative;
}
.blog-sec .blog-cards .blog-card:hover .blog-title{
border-bottom: 10px solid var(--ltn__secondary-color) !important;
transition: all 0.5s linear;
}
.blog-sec .blog-cards .blog-card  img{
    height: 500px !important;

    width: 100%;
}
.blog-sec .blog-cards .blogcard-layer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.262);

}

.blog-sec .blog-cards .blog-card .content{

    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    max-width: 560px;
    z-index: 2;

}
.blog-sec .blog-cards .blog-card .content .blog-title{

    font-size: clamp(28px,2.67559vw + 7.45151px,30px);
    color: white;
    font-weight: 900;
line-height: 90%;
padding-bottom: 10px;
border-bottom: 10px solid transparent;
margin-bottom: 0px;
/* border-radius: 0px 0px 20px 20px; */
transition: all 0.5s linear;
width: fit-content;
} 

.blog-sec .blog-cards .blog-card .content span{
    color: #cccccc;
}
.blog-sec .blog-cards .blog-card .general{
    position: absolute;
    top: 20px;
    left: 20px;
    width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--ltn__secondary-color) ;
    color: white;
    z-index: 2;
}


@media only screen and (max-width: 1565px) {
    .blog-sec .blog-cards .blog-card{
        max-height: 400px !important;

    }
    .blog-sec .blog-cards .blog-card  img{
        height: 400px !important;

    }
    .blog-sec .blog-cards .blog-card .content .blog-title {
        font-size: clamp(18px,1.57559vw + 5.45151px,30px);

    }
}

@media only screen and (max-width: 1565px) {
    .blog-sec .blog-cards{
        grid-template-columns: repeat(2,1fr);
    }
}
@media only screen and (max-width: 600px) {


    .blog-sec{

        padding: 0px 20px 20px 20px;
    }

    .blog-sec .blog-heading h1{
        margin: 0px;
        font-size: 8vw;
        
    }

    .blog-sec .blog-banner{
        height: 300px;
        width: 100%;
        position: relative;
    }
    .blog-sec .blog-banner .general{
        top: 10px;
        left: 10px;
        width: 94px;

    }
    .blog-sec .blog-banner img{
        height: 300px;
        object-fit: cover;
    }
    .blog-sec .blog-banner .content{
    
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 20px;
    
        max-width: 560px;
        z-index: 2;
    
    }
    .blog-sec .blog-banner .content .blog-title{
    
font-size: 5vw;
margin-bottom: 5px;
    
    } 
    .blog-sec .blog-cards{
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
    }
    .blog-sec .blog-cards .blog-card{
        max-height: 300px !important;
    }
    .blog-sec .blog-cards .blog-card:hover .blog-title{
    border-bottom: 10px solid var(--ltn__secondary-color) !important;
    transition: all 0.5s linear;
    }
    .blog-sec .blog-cards .blog-card  img{
        height: 300px !important;
        object-fit: cover;
    
    }
    
    .blog-sec .blog-cards .blog-card .content{
    
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
    
    }
    .blog-sec .blog-cards .blog-card .content .blog-title{
    
        font-size: 5vw;
padding-bottom: 0px;
    } 

    .blog-sec .blog-cards .blog-card .general{
        top: 10px;
        left: 10px;
    }
    
}


