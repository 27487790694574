.blog2-sec{
    max-width: 700px;
    margin: 0px auto;
    padding: clamp(40px,6.6778vw - 11.28548px,80px) 0px ;
    
}
.blog2-sec h2{
    line-height: 100%;
}
.blog2-sec p,ul li {
font-size: 20px;
line-height: 120%;
}
.blog2-sec ul li a{
    font-weight: bold;
    color: black;
}
.blog2-sec .bottom-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog2-sec .bottom-buttons a{
    font-size: 18px;
line-height: 120%;

}

.blog2-sec .bottom-buttons a:last-child{
    text-decoration: underline;
}



@media only screen and (max-width: 600px) {

    .blog2-sec ,.blog3-sec ,.blog4-sec,.blog5-sec,.blog6-sec{
        width: 100%;
        margin: unset;
        padding:0px 20px 20px 20px !important;
        
    }
    h3{
        line-height: 100%;
    }
    .blog2-sec h2,.blog3-sec h2,.blog4-sec h2,.blog5-sec h2,.blog6-sec h2{
        line-height: 100%;
        margin:5px 0px !important;
    }
    .blog2-sec p,.blog3-sec p,.blog4-sec p,.blog5-sec p,.blog6-sec p {
    font-size: 16px !important;
    line-height: 110% !important;
    }
    .blog2-sec ul li,.blog3-sec ul li,.blog4-sec ul li,.blog5-sec ul li,.blog6-sec ul li{
        font-size: 16px !important;
        line-height: 110% !important;
        margin-top: 5px !important;
    }
    .blog2-sec ul,.blog3-sec ul,.blog4-se ul,.blog5-sec ul,.blog6-sec ul{
        margin-bottom: 5px !important;
    }
    .blog2-sec h3,.blog3-sec h3,.blog4-sec h3,.blog5-sec h3,.blog6-sec h3{
        font-size: 5vw;
    }
    .blog3-sec p, ul li {
        font-size: 16px;
        line-height: 120%;
    }

}