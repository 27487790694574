.review-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    background-color: #fff;
    margin-top: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.review-box p {
    color: var(--ltn__paragraph-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
    margin-top: 15px;
}

.icon-star {
    width: 24px;
    height: 24px;
}

.review-person-name h5 {}

.review-person-name p {}

@media only screen and (max-width: 790px) {
    .review-box {
        padding: 30px 10px 30px 30px;
    }

    .review-box p {
        font-size: 14px;
        width: 100%;
    }
}