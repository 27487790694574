.h-products {
  padding: 7%;
  max-width: 1300px;
  margin: 0px auto;
}

.h-products .products-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px 30px;
}

.flip-card {
  width: 300px !important;
  height: 300px !important;
  position: relative;
  box-shadow: var(--ltn__box-shadow-4);
  border-radius: 10px;
  transition: all 0.6s linear;
  /* border: 1px solid red; */
}

.flip-card .category-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  line-height: 115px;
  font-size: 45px;
  border-radius: 100%;
  background: var(--section-bg-5);
  color: var(--ltn__secondary-color);
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  border-radius: 10px;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.flip-card:hover .category-btn {
  opacity: 0;
  transition: all 0.6s linear;
}

.category-btn {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  bottom: -20px;
  z-index: 3;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: var(--ltn__secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s linear;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: var(--ltn__box-shadow-4);
  border-radius: 10px;
  padding: 20px;
}

.flip-card-front {
  background: white;
  color: black;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}

.flip-card-back {
  background: var(--ltn__secondary-color);
  color: white;
  transform: rotateY(180deg);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.flip-card p {
  font-size: 17px;
  color: #fff;
  text-align: center;
  line-height: 120%;
  letter-spacing: 1.3px;
  padding: 0;
  margin: 0;
}
/* 
@media screen and (min-width: 1120px) {
  .products-cards > :nth-child(-n + 3) {
    flex: 0 0 calc(33.333% - 20px);
  }

  .products-cards > :nth-child(n + 4) {
    flex: 0 0 calc(50% - 180px);
  }
} */


@media screen and (max-width:1024px) {


  .flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner ,.flip-card:active .flip-card-inner{
  transform: rotateY(0deg);
}
.flip-card-back {
  display: none;
}
}