* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.contactsectionLink {
  width: 80%;
  margin: auto;
  padding-top: 30px;
}

.contactsectionLink a {
  margin-right: 10px;
  color: #000;
  font-weight: 400;
}

.contactInfo-container {
  width: 80%;
  margin: auto;
}

.contactInfo-center {
  width: 800px;
  margin: auto;
  padding: 50px 0px;
}

.contactInfo-container h2 {
  font-size: 65px;
  font-weight: 500;
  color: var(--ltn__heading-color);
}

.contactInfo-container h2 span {
  color: var(--ltn__secondary-color);
}

.contactInfo-center p {
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

.contactInfo-center h3 {
  font-size: 30px;
  font-weight: 500;
  color: var(--ltn__heading-color);
}

.headOffice-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
}

.headOffice-container div {
  display: flex;
  flex-direction: column;
  color: var(--ltn__secondary-color);
  font-weight: 600;
}

.headOffice-container div span {
  font-size: 22px;
  font-weight: 500;
  color: var(--ltn__heading-color);
}

/* Location Css */
/* .shape {
    border-top: 100px solid var(--ltn__secondary-color);
    border-right: 50px solid transparent;
    height: 0;
    width: 50%;
} */
.contact-container-flex {
  display: flex;
}

.left-shape-container-contact {
  width: 50%;
}

.shapecontact {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
  height: 650px;
  background-color: var(--ltn__secondary-color);
  /* border-top: 650px solid var(--ltn__secondary-color);
    border-right: 70px solid transparent; */
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.inner-shape-contact {
  position: absolute;
  width: 100%;
  height: 600px;
  /* margin-top: 20px; */
  background-color: gray;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* margin-top: -625px; */
  /* border-top: 600px solid gray;
    border-right: 70px solid transparent; */
}
.right-shape-container-contact {
  width: 50%;
}
.contact-address-head-section {
  width: 100%;
}

.contact-address-head-section {
  background-color: #f5f3f1;
  padding: 20px 10px 30px 150px;
}

.contact-address-head-section h2 {
  font-size: 65px;
  font-weight: 600;
  color: var(--ltn__secondary-color);
}

.contact-address-head-section h5 {
  color: var(--ltn__heading-color);
  font-size: 30px;
  margin-top: -20px;
  font-weight: 700;
  margin-bottom: 0px;
}

.contact-address-head-section a {
  font-size: 25px;
  font-weight: 500;
  line-height: 120%;
  color: var(--ltn__paragraph-color);
}

.panel-text-wrapper {
  padding: 20px 10px 30px 150px;
}

.open-text {
  color: var(--ltn__heading-color);
  font-size: 25px;
  font-weight: 700;
}

.fancy-text {
  font-size: 50px;
  font-weight: 800;
  margin-top: -50px;
  color: var(--ltn__secondary-color);
}

.hours-table {
  margin-top: -10px;
  width: 60%;
}

table.hours-table th {
  color: black;
  padding-left: 20px !important;
}
table.hours-table td {
  color: black;
  padding: 10px;
  padding-left: 50px !important;
}
table.hours-table tr:nth-child(odd) {
  background: rgb(241, 241, 241);
}

@media screen and (max-width: 1300px) {
  .contact-address-head-section {
    background-color: #f5f3f1;
    padding: 20px 10px 30px 100px;
  }
  .panel-text-wrapper {
    padding: 20px 10px 30px 100px;
  }
  .hours-table {
    width: 80%;
  }
}
@media screen and (max-width: 1025px) {
  .contactInfo-container {
    width: 100%;
  }

  .contactInfo-center {
    padding: 50px 20px 50px 20px;
  }
  .contactInfo-container h2{
    font-size: 40px;
    margin-bottom: 5px;
  }
  .contactInfo-container h3{
    font-size: 25px;
    margin-bottom: 0px;
  }
  .contact-address-head-section {
    padding: 30px 10px 30px 20px;
}
.shapecontact {
  height: 390px;
}
.inner-shape-contact {

  height: 350px;

}
.fancy-text {
  font-size: 30px;

}
  .contact-address-head-section h2 {
    font-size: 40px;
}
.contact-address-head-section h5 {
  font-size: 25px;
}
.contact-address-head-section a {
  font-size: 18px;
}
  .contactInfo-center p {
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 10px;
}
.headOffice-container div span{
font-size: 16px;
}
.headOffice-container{
  gap: 10px;
}

  .contact-container-flex {
    display: flex;
    flex-direction: column-reverse;
  }

  .right-shape-container-contact {
    width: 100%;
  }

  .left-shape-container-contact {
    width: 100%;
  }

  .shapecontact {
    width: 100%;
  }
}

@media screen and (max-width: 830px) {
  .contactInfo-center {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .contactInfo-container h2,
  h3 {
    font-size: 6vw !important;
    margin-bottom: 0px;
  }
  .headOffice-container {
    gap: 10px;
  }

  .headOffice-container div span {
    line-height: 100%;
  }
  .contactInfo-center {
    padding: 0px 20px 30px 20px;
  }

  .headOffice-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact-address-head-section {
    width: 100%;
    padding: 20px 10px 30px 20px;
  }

  .panel-text-wrapper {
    width: 100%;
    padding: 20px 10px 30px 20px;
  }
  .contactInfo-container h2 {
    font-size: 30px;
  }

  .contactInfo-center p {
    font-size: 16px;
  }

  .contact-address-head-section h2 {
    font-size: 30px;
  }

  .contact-address-head-section h5 {
    font-size: 20px;
  }
  .contact-address-head-section a {
    font-size: 18px;
  }
  .fancy-text {
    font-size: 25px;
    font-weight: 800;
    margin: 0;
    line-height: 100%;
    /* margin-top: -50px; */
    color: var(--ltn__secondary-color);
  }
  .close-div {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 10px;
  }

  .hours-table {
    width: 100%;
  }

  .hours-table td {
    display: flex;
    justify-content: flex-end;
  }
}
