.reviews-container {
  max-width: 1100px;
  margin: auto;
  padding: 50px 20px;
}

.reviews-container h1 {
  font-size: 75px;
  font-weight: 900;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.icon-qoutes-1 {
  font-size: 100px;
  color: var(--ltn__secondary-color);
}

.icon-qoutes-2 {
  position: relative;
  font-size: 100px;
  color: var(--ltn__secondary-color);
  margin-top: 20px;
}

.reviews-container h1 span {
  color: var(--ltn__secondary-color);
}

.review-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.review-text-left {
}

.review-text-right {
  padding: 30px 0px;
}

@media only screen and (max-width: 790px) {
  .review-item {
    grid-template-columns: repeat(1, 1fr);
  }

  .review-text-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
  }

  .review-text-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    padding: 0px 0px;
  }

  .reviews-container h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .review-text-left {
    grid-template-columns: repeat(1, 1fr);
  }

  .review-text-right {
    grid-template-columns: repeat(1, 1fr);
  }

  .reviews-container h1 {
    font-size: 30px;
  }

  .icon-qoutes-1 {
    font-size: 60px;
  }

  .icon-qoutes-2 {
    font-size: 60px;
  }
}
