* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}

.privacyInfo-center {
  width: 800px;
  margin: auto;
  padding: 50px 0px;
}

.privacyInfo-center h2 {
  font-size: 65px;
  font-weight: 500;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.privacyInfo-center h2 span {
  line-height: 80%;
  color: var(--ltn__secondary-color);
}

.privacyInfo-center p {
  color: var(--ltn__paragraph-color);
  font-size: 20px;
  line-height: 140%;
  font-weight: 500;
}

.privacyInfo-center ol li {
  color: var(--ltn__paragraph-color);
  font-size: 20px;
  line-height: 120%;
}
.privacyInfo-center ul li {
  color: var(--ltn__heading-color);
  font-weight: 600;
}

.privacyInfo-center h4 {
  font-size: 40px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--ltn__heading-color);
}

.privacyInfo-center h4 span {
  color: var(--ltn__secondary-color);
}

.privacyInfo-center h5 {
  font-size: 35px;
  font-weight: 700;
}
.privacyInfo-center h6 {
  font-size: 20px;
  font-weight: 700;
}

.privacyInfo-center h5 span {
  color: var(--ltn__secondary-color);
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {

  border: .25em solid rgb(63, 90, 56) !important;
}
div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {

  background-color: rgb(63, 90, 56) !important;
}

@media screen and (max-width:1024px) {


  .privacyInfo-center {
width: 100%;
    padding: 50px;
  }
  .privacyInfo-center h2 {
    font-size: 40px;
  }

  
  .privacyInfo-center p {
    font-size: 18px;
    line-height: 120%;
  }
  
  .privacyInfo-center ol li {
    font-size: 18px;
  }

  
  .privacyInfo-center h4 {
    font-size: 25px;

  }
  

  
  .privacyInfo-center h5 {
    font-size: 20px;
  }
  .privacyInfo-center h6 {
    font-size: 18px;
  }
  

  
  
}

@media (max-width: 600px) {
  .privacyInfo-center {
    width: 100%;
    margin: auto;
    padding: 0px 20px 30px 20px;
  }

  .privacyInfo-center h2 {
    font-size: 6vw;
    margin-bottom: 5px;
  }

  .privacyInfo-center p {
    text-align: left;
    font-size: 16px;
    line-height: 120%;
  }

  .privacyInfo-center ol li {
    font-size: 16px;
    margin-top: 5px;
  }
  .privacyInfo-center ul li {
    font-size: 16px;
    margin-top: 10px;
  }

  .privacyInfo-center h4 {
    font-size: 20px;

    margin: 5px 0px;
  }

  .privacyInfo-center h4 span {
    color: var(--ltn__secondary-color);
  }

  .privacyInfo-center h5 {
    font-size: 35px;
    font-weight: 700;
  }
  .privacyInfo-center h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 100%;
  }

  .privacyInfo-center h5 span {
    color: var(--ltn__secondary-color);
  }
}
