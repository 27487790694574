.blog6-sec{
    max-width: 700px;
    margin: 0px auto;
    padding: clamp(40px,6.6778vw - 11.28548px,80px) 0px ;
    
}
.blog6-sec h2{
    line-height: 100%;
}
.blog6-sec p,ul li {
font-size: 20px;
line-height: 120%;
}
.blog6-sec ul li a{
    font-weight: bold;
    color: black;
}
.blog6-sec p a{
    font-weight: bold;
    color: black;
}
.blog6-sec .bottom-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog6-sec .bottom-buttons a{
    font-size: 18px;
line-height: 120%;

}
.blog6-sec .bottom-buttons a:first-child{
    text-decoration: underline;
}