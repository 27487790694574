.splash-screen {
  position: relative;
  background-color: #fff !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loader {
    position: relative;
    // width: 100%;
    .car__body {
      -webkit-animation: shake 0.2s ease-in-out infinite alternate;
      animation: shake 0.2s ease-in-out infinite alternate;
    }
    .car__line {
      transform-origin: center right;
      stroke-dasharray: 22;
      -webkit-animation: line 0.8s ease-in-out infinite;
      animation: line 0.8s ease-in-out infinite;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    .car__line--top {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    .car__line--middle {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    .car__line--bottom {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }

    @-webkit-keyframes shake {
      0% {
        transform: translateY(-1%);
      }
      100% {
        transform: translateY(3%);
      }
    }

    @keyframes shake {
      0% {
        transform: translateY(-1%);
      }
      100% {
        transform: translateY(3%);
      }
    }
    @-webkit-keyframes line {
      0% {
        stroke-dashoffset: 22;
      }
      25% {
        stroke-dashoffset: 22;
      }
      50% {
        stroke-dashoffset: 0;
      }
      51% {
        stroke-dashoffset: 0;
      }
      80% {
        stroke-dashoffset: -22;
      }
      100% {
        stroke-dashoffset: -22;
      }
    }
    @keyframes line {
      0% {
        stroke-dashoffset: 22;
      }
      25% {
        stroke-dashoffset: 22;
      }
      50% {
        stroke-dashoffset: 0;
      }
      51% {
        stroke-dashoffset: 0;
      }
      80% {
        stroke-dashoffset: -22;
      }
      100% {
        stroke-dashoffset: -22;
      }
    }
  }

  .line-breaker {
    width: 100%;
    height: 2.3px;
    background-color: #000;
    // background-color: #4a6543;

    border-radius: 100%;
    transition: all 0.8s ease-in-out;
  }
}
.typewriter {
  h1 {
    font-family: "Poppins", sans-serif !important;

    a {
      font-family: "Poppins", sans-serif !important;

      color: #000;
      span {
        &:first-child {
        }
        &:last-child {
          color: #4a6543;
        }
      }
    }
  }

  .Typewriter__cursor {
    color: #000 !important;
  }
}

// .flip-animate {
//   perspective: 1000px;

//   span {
//     position: relative;
//     display: inline-block;
//     padding: 0;
//     transition: transform 0.3s;
//     transform-origin: 50% 0;
//     transform-style: preserve-3d;

//     &:before {
//       position: absolute;
//       top: 100%;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       content: "Extended Trust";
//       transition: color 0.3s;
//       transform: rotateX(-90deg);
//       transform-origin: 50% 0;
//       text-align: center;
//     }
//   }

//   @keyframes flipAnimation {
//     0% {
//       transform: rotateX(0) translateY(0);
//     }
//     100% {
//       transform: rotateX(90deg) translateY(-22px);
//     }
//   }

//   span {
//     animation: flipAnimation 0.8s ease-in-out 1.5s forwards infinite;
//     animation: flipAnimation 0.8s linear 2s forwards infinite;
//   }

//   span:before {
//     color: orangered;
//   }
//   span:nth-child(even) {
//     animation-delay: 2s;
//   }
// }

// .splash-screen {
//   h1 {
//     overflow: hidden;
//     span {
//       position: relative;
//       top: 200px;
//     }
//   }
// }

.smoke-layerTop {
  left: 0;
  height: 140px;
  transform: rotate(180deg);
  top: -40px;
  display: none;
}

.showLoop {
  position: fixed;
  inset: 1;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 1.3s linear;
  background-color: #fff;
}
.hideLoop {
  transition: all 1.3s linear;
  opacity: 0 !important;
  z-index: -9;
}
