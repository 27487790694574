.repair-about {
  /* background-image: url("/assets/img/selfmade/Images/repairabout.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 5% 7%;
  position: relative;
}

.repair-about .smoke-layer-top {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  transform: rotate(180deg);
  height: 150px;
  background: url("../../../public/assets/img/selfmade/Images/smoke.png");
  background-position: center;
  background-size: cover;
  z-index: 6;
}

.ra-content {
  position: relative;
  z-index: 3;
  max-width: 900px;
  margin: 0px auto;
}

.ra-content h1 {
  text-transform: uppercase;
}

.ra-content p {
  margin-bottom: 0;
  font-weight: 400px;
  line-height: clamp(30px, 1.66945vw + 7.17863px, 20px);
  font-size: clamp(18px, 1.66945vw + 7.17863px, 25px);
  width: 100%;
}

.ra-layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.414);
}

@media screen and (max-width:1024px) {
  .repairsForm-container h2 {
    font-size: 40px;
}
  .section-title{
    font-size: 25px ;
  }
  .ra-content p{
    font-size: 18px;
    line-height: 120%;
  }
  .repairsForm-container {
    padding: 0px 20px 50px 20px;
  }

}

@media screen and (max-width: 600px) {
  .ra-content h1{
    font-size: 6vw;
  }
  .repairsForm-container h2 {
    font-size:6vw;
}
.repairsForm-container {
  padding:  20px;
}

  h1 {
    font-size: 24px;
    /* width: 70%; */
  }

  p {
    font-size: 16px;
    line-height: 100%;
    /* width: 70%; */
  }
}
