.repairsForm-container {
  /* width: 50%; */
  max-width: 950px;
  padding: 50px 0px;
  margin: auto;
}

.repairsForm-container h2 {
  font-size: 65px;
  font-weight: 500;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.repairsForm-container h2 span {
  line-height: 80%;
  color: var(--ltn__secondary-color);
}

.object-cover {
  object-fit: cover !important;
}

.ErrorMessage {
  color: red;
  margin-top: -25px;
}

@media only screen and (max-width: 770px) {
  .repairsForm-container {
    width: 100%;
    padding: 30px;
  }

  .repairsForm-container h2 {
    font-size: 30px;
    line-height: 90%;
  }

  .repairsForm-container h2 span {
    line-height: 80%;
  }
  .ra-content p {
    line-height: 120% !important;
    font-size: 16px !important;
  }
  .repairsForm-container input {
    margin-bottom: 5px;
  }
  .repairsForm-container .File-label {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 420px) {
  .repairsForm-container h2 {
    font-size: 22px;
    line-height: 90%;
  }
}
