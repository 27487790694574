.awards-sec {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.about-growing .section-title {
  padding: 50px 0px;
  font-size: 4vw;

  position: relative;
}
.about-growing .section-title::before {
  content: "";
  background: linear-gradient(90deg, rgba(231, 227, 223, 0), #e7e3df);
  position: absolute;
  left: 0;
  right: -25%;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.awards-sec .award-img {
  max-width: 300px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.aboutgrowing-img {
  min-height: 470px;
}

@media (max-width: 1500px) {
  .about-growing .section-title {
    padding: 20px 0px;
    font-size: 40px;
  }
}

@media (max-width: 600px) {
  .about-growing .contain {
    padding: 0px 20px 20px 20px;
  }
  .awards-sec {
    padding: 0px;
    margin: 0px;
  }
  .about-growing .ltn__about-us-area {
    padding: 0px !important;
  }
  .about-growing .section-title {
    padding: 20px 0px;
    font-size: 8vw;
  }
  .about-growing .align-self-center {
    padding: 0px;
  }
  .about-growing .row {
    margin: 0px;
  }
  .about-growing .section-title::before {
    right: 0;
  }
  .about-img-right {
    margin-top: 20px;
  }
  .awards-sec .award-img {
    max-width: 150px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
  .aboutgrowing-img {
    min-height: unset;
  }
}
