.values-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-gap: 40px;
  row-gap: 40px;
  max-width: 1366px;
  padding-left: 100px;
  width: 100%;
  margin: 0px auto;
}

.values-content .second {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.values-content .second h2 {
  margin-bottom: 0;
}

.values-content .second img {
  width: 80px;
  height: 80px;
}

@media (max-width: 1599px) {
  .values-content .second img {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width:1024px) {
  .values-sec{
    padding:
    0px 50px 30px 50px;
  }
  .values-content .second img {
    width: 40px;
    height: 40px;
}
  
}
