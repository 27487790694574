.meettheteam-container {
  max-width: 1350px;
  margin: auto;
  padding: 50px 20px;
}

.meettheteam-text-container {
  max-width: 750px;
  margin: auto;
}

.meettheteam-text-container h2 {
  font-size: 60px;
  font-weight: 900;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.meettheteam-text-container h2 span {
  line-height: 80%;
  color: var(--ltn__secondary-color);
}

.meettheteam-text-container p {
  font-size: 18px;
  font-weight: 500;
  color: var(--ltn__paragraph-color);
}

@media screen and (max-width:1024px) {
  .meettheteam-container {
    padding: 50px !important;
  }
  .meettheteam-text-container h2 {
    font-size: 40px;

}
  .meettheteam-text-container p {
    font-size: 18px;
    line-height: 120%;

}
.team-title-box h2 {
  font-size: 40px !important;
}
  
}
@media screen and (max-width:800px) {
  .meettheteam-container {
    padding: 20px !important;
  }
  
}

@media only screen and (max-width: 600px) {

  
  .meettheteam-text-container h2 {
    font-size: 25px;

}


  .meettheteam-text-container {
    max-width: 100%;
  }

  .meettheteam-text-container h2 {
    font-size: 45px;
    width: 100%;
  }

  .meettheteam-text-container p {
    font-size: 16px;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .meettheteam-text-container h2 {
    font-size: 25px;
  }
  .meettheteam-container {
    padding-top: 0px;
  }
  .reviews-container {
    padding-top: 0px !important;
  }
  .repair-about {
    padding-top: 0px !important;
  }
}
