.team-title-box {
  max-width: 750px;
  margin: auto;
  border-bottom: 1px solid rgb(206, 206, 206);
  margin-bottom: 20px;
}

.team-title-box h2 {
  font-size: 60px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.teamPerson-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  gap: 30px;
  margin: auto;

  grid-template-rows: auto;
  grid-auto-rows: min-content;
}

.teamPerson-box {
  position: relative;
  /* padding-top: 20px; */
  width: 100%;
  overflow: hidden;
  /* background: #242424; */
  /* border: 1px solid red; */
}

.teamPerson-box::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}

.teamPerson-box img {
  object-fit: cover;
  object-position: center top;
  height: 400px;
  width: 100%;
}

.teamPerson-box-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, rgba(0, 0, 0, 0)),
    to(#000000d5)
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    #000000d5 100%
  );
  background-color: transparent;
  z-index: 2;
}

.teamPerson-box-overlay h3 {
  font-size: 32px;
  font-weight: 900;
  color: #fff;
  line-height: 80%;
}

.teamPerson-box-overlay h3::before {
  content: "";
  position: absolute;
  top: auto;
  left: 0;
  height: 35px;
  width: 20px;
  background: var(--ltn__secondary-color);
  -webkit-transform: skew(-15deg);
  transform: skew(-15deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  z-index: -1;
}

.teamPerson-box-overlay span {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  line-height: 80%;
}
@media screen and (max-width:1024px) {

  .teamPerson-Container {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
    .teamPerson-box img {
      object-fit: cover;
      height: 500px;
      width: 100%;
  }
  
}
@media only screen and (max-width: 600px) {
  .team-title-box h2 {
    font-size: 25px !important;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .teamPerson-Container {
    flex-wrap: wrap;
  }
  .teamPerson-box {
    position: relative;
    min-height: 300px;
    max-width: 300px;
    width: 100%;
    overflow: hidden;
    /* border: 1px solid red; */
  }
}

@media only screen and (max-width: 600px) {
  .teamPerson-Container {
    grid-template-columns: repeat(1, 1fr);
  }
  .teamPerson-box {
    position: relative;
    height: 350px;
    max-width: 350px;
    width: 100%;
    overflow: hidden;
    /* border: 1px solid red; */
  }
}
