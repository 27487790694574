.becomedealer-sec {
  /* background: url("/assets/img/selfmade/Images/dealership.jpg"); */
  background: url("../../../public/assets/img/selfmade/Images/dealership.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* margin-bottom: 50px; */
}
.becomedealer-sec .smoke-layer {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  transform: rotate(180deg);
  height: 150px;
  background: url("../../../public/assets/img/selfmade/Images/smoke.png");
  /* background: url("/assets/img/selfmade/Images/smoke.png"); */
  background-position: center;
  background-size: cover;
  z-index: 6;
}
.becomedealer-sec .inner {
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  position: relative;
  z-index: 2;
}
.becomedealer-sec .inner p,
.head {
  color: white;
  line-height: 140%;
  text-align: justify;
}
.becomedealer-sec .inner p {
  line-height: clamp(25px, 1.66945vw + 7.17863px, 10px);
  font-size: clamp(20px, 1.66945vw + 7.17863px, 20px);
  text-align: justify;
  letter-spacing: 1px;
}

.becomedealer-sec .layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.601);
}
@media screen and (max-width:1024px) {
  .smoke-layer{
    height: 40px;
  }
  
}
@media screen and (max-width: 600px) {
  .becomedealer-sec {
    height: unset;

    padding: 60px 20px;
  }
  .becomedealer-sec .inner {
    margin-top: 20px;
    row-gap: 20px;
  }
  .becomedealer-sec .inner p,
  .head {
    margin-bottom: 0px;
  }
  .becomedealer-sec .inner p {
    font-size: 16px;
    line-height: 120%;
    text-align: center;
  }
  .becomedealer-sec .btn-wrapper {
    margin: 0px;
  }

  .becomedealer-sec .smoke-layer {
    height: 50px;
  }
}
