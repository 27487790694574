.contactform-left-container {
  width: 50%;
}

.contactform-left-container p {
  color: var(--ltn__paragraph-color);
  padding: 20px 0px 20px 180px;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

.ErrorMessage {
  color: red;
  margin-top: -25px;
}


.contactform-SendmsgText {
  background-color: #f5f3f1;
  padding: 20px 10px 20px 180px;
}

.contactform-SendmsgText h2 {
  font-size: 3.8vw;
  font-weight: 800;
  color: var(--ltn__heading-color);
  line-height: 60px;
  margin-bottom: 0;
}

.contactform-SendmsgText h2 span {
  font-weight: 800;
  color: var(--ltn__secondary-color);
}

.contactform-right-container {
  width: 50%;
  object-fit: cover;
}

.contactform-right-container img {
  width: 100%;
  object-fit: cover;
}

#contact-form {
  padding: 0px 100px 40px 180px;
}
@media screen and (max-width: 1600px) {
  .contactform-left-container p {
    padding: 20px 20px 0px 100px;
    margin-bottom: 20px;
  }
  #contact-form {
    padding: 0px 50px 40px 100px;
  }
  .contactform-SendmsgText {
    padding: 20px 10px 20px 100px;
  }
}

@media screen and (max-width: 1025px) {
  .contactform-SendmsgText {
    background-color: #f5f3f1;
    padding: 20px 20px 20px 20px;
  }

  #contact-form {
    padding: 0px 20px 40px 20px;
  }

  .contactform-send {
    display: flex;
    flex-direction: column;
  }

  .contactform-left-container {
    width: 100%;
  }

  .contactform-right-container {
    width: 100%;
  }
  .contactform-right-container img {
    height: 400px !important;
    object-fit: cover;
  }
  .contactform-left-container p {
    padding: 10px 20px;
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 0px;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="submit"],
  input[type="number"],
  input[type="file"],
  input[type="tel"],
  .File-label,
  textarea {
    height: 50px;

    margin-bottom: 15px;
  }
  .contactform-SendmsgText h2 {
    font-size: 40px;
    line-height: 120%;
    margin: 0px;
  }
}
@media screen and (max-width: 600px) {
  .contactform-SendmsgText {
    background-color: #f5f3f1;
    padding: 20px 20px 20px 20px;
  }

  #contact-form {
    padding: 0px 20px 40px 20px;
  }

  .contactform-send {
    display: flex;
    flex-direction: column;
  }

  .contactform-left-container {
    width: 100%;
  }

  .contactform-right-container {
    width: 100%;
  }
  .contactform-left-container p {
    padding: 10px 20px;
  }
  .contactform-SendmsgText h2 {
    font-size: 8vw;
    line-height: 120%;
    margin: 0px;
  }
}
