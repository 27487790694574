.faq-item {
  border-bottom: 1px solid #ccc;
  padding: 12px;
  cursor: pointer;
}

.faq-item-1 {
  border-top: 1px solid #ccc !important;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 25px;
  font-weight: 700;
  color: #3b3b3b;
}

.faqs-container {
  width: 50%;
  margin: auto;
  padding: 100px 0px;
}

.faqs-container h1 {
  font-size: 75px;
  font-weight: 900;
  line-height: 90%;
  color: var(--ltn__heading-color);
}

.faqs-container h1 span {
  color: var(--ltn__secondary-color);
}

.faq-answer {
  opacity: 0;
  max-height: 0;
  font-size: 20px;
  overflow: hidden;
  transition: opacity 1s ease, max-height 0.3s ease;
}

.faq-answer.show {
  opacity: 1;
  max-height: 200px;
}

@media only screen and (max-width: 1135px) {
  .faqs-container {
    width: 80%;
  }

  .faqs-container h1 {
    font-size: 50px;
  }

  .faq-answer {
    font-size: 16px;
  }

  .faq-question {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .faqs-container {
    width: 100%;
    padding: 0px 20px 20px 20px;
  }

  .faqs-container h1 {
    font-size: 6vw;
  }
  .faq-question {
    font-size: 18px;
    line-height: 120%;
  }
  .faq-answer {
    font-size: 16px;
    line-height: 120%;
  }
}

/* -------------------------------------------------- */
