.workcard {
  width: 100%;
  display: block;
  padding-top: 400px;
  transform-style: preserve-3d;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-right: 20px;
  border: 5px solid #fff;
}

.worksec {
  padding-top: 100px;
}

.worksec .section-title {
  margin-bottom: 50px;
  text-align: center;
}

.worksec .dcard {
  position: relative;
  display: block;
  perspective: 500px;
}

.dcard {
  transition: all 0.5s linear;
}

.worksec .frame {
  background: rgba(0, 0, 0, 0.6);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.worksec .frame:after {
  width: calc(100% - 40px);
  border: 2px dashed rgba(255, 255, 255, 0.5);
  height: calc(100% - 40px);
  content: "";
  display: block;
  left: 20px;
  top: 20px;
  position: absolute;
  transform: translateZ(30px);
}

.worksec h2 {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) translateZ(100px);
  text-align: center;
  width: 100%;
  transition: all 1s linear;
}

.dcard:hover h2 {
  bottom: 40%;
  transition: all 1s linear;
}

.worksec .trigger {
  position: absolute;
  height: 33.333333%;
  width: 33.333333%;
  display: block;
  z-index: 2;
}

.worksec .trigger:nth-child(1) {
  left: 0%;
  top: 0%;
}

.worksec .trigger:nth-child(1):hover ~ .workcard {
  transform: rotateY(8deg) rotateX(-5deg);
}

.worksec .trigger:nth-child(2) {
  left: 33.333333%;
  top: 0%;
}

.worksec .trigger:nth-child(2):hover ~ .workcard {
  transform: rotateY(0deg) rotateX(-5deg);
}

.worksec .trigger:nth-child(3) {
  left: 66.666666%;
  top: 0%;
}

.worksec .trigger:nth-child(3):hover ~ .workcard {
  transform: rotateY(-8deg) rotateX(-5deg);
}

.worksec .trigger:nth-child(4) {
  left: 0%;
  top: 33.333333%;
}

.worksec .trigger:nth-child(4):hover ~ .workcard {
  transform: rotateY(8deg);
}

.worksec .trigger:nth-child(5) {
  left: 33.333333%;
  top: 33.333333%;
}

.worksec .trigger:nth-child(5):hover ~ .workcard {
  transform: rotateY(0deg) rotateX(0deg);
}

.worksec .trigger:nth-child(6) {
  left: 66.666666%;
  top: 33.333333%;
}

.worksec .trigger:nth-child(6):hover ~ .workcard {
  transform: rotateY(-8deg) rotateX(0deg);
}

.worksec .trigger:nth-child(7) {
  left: 0%;
  top: 66.666666%;
}

.worksec .trigger:nth-child(7):hover ~ .workcard {
  transform: rotateY(8deg) rotateX(5deg);
}

.worksec .trigger:nth-child(8) {
  left: 33.333333%;
  top: 66.666666%;
}

.worksec .trigger:nth-child(8):hover ~ .workcard {
  transform: rotateY(0deg) rotateX(5deg);
}

.worksec .trigger:nth-child(9) {
  left: 66.666666%;
  top: 66.666666%;
}

.worksec .trigger:nth-child(9):hover ~ .workcard {
  transform: rotateY(-8deg) rotateX(5deg);
}

@media only screen and (max-width: 767px) {
  .dcard {
    margin-bottom: 10px;
  }

  .worksec {
    padding-top: 50px;
  }

  .worksec .trigger {
    display: none;
  }
}

.slick-slide {
  opacity: 0.7 !important;
}

.slick-center {
  outline: none;
  width: 546px;
  transform: scale(1.5);
  z-index: 999;
  position: relative;
  padding: 200px 0px;
}

.slider-item.slick-center {
  opacity: 1 !important;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slick-slide.slick-active.slick-center.slick-current {
  opacity: 1 !important;
}

.worksec .slick-prev {
  /* background-image: url("/assets/img/icons/left-arrow.svg") !important; */
  background-image: url("../../../public/assets/img/icons/left-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: unset;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%) rotate(180deg);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  bottom: 0;
  left: 50% !important;
}

.worksec .slick-next {
  background-image: url("../../../public/assets/img/icons/left-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: unset;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  bottom: 0;
  left: unset;
  right: 50% !important;
}

.ltn__testimonial-area .slick-dots {
  display: none !important;
}
