.values-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 40px;
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
}
.values-sec .values-content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.values-sec .values-content .ist {
  display: flex;
  column-gap: 20px;
}
.values-sec .values-content .ist img {
  max-width: 40px !important;
  max-height: 40px !important;
}
.values-sec .values-content .ist div {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.values-sec .values-content .ist h1 {
  line-height: 0.9;
  font-size: clamp(30px, 1.66945vw + 7.17863px, 35px);
  margin-bottom: 0;
  font-family: var(--ltn__heading-font);
  letter-spacing: 0.1 !important;
}
.values-sec .values-content .ist p {
  line-height: clamp(28px, 1.66945vw + 7.17863px, 20px);
  font-size: clamp(18px, 1.66945vw + 7.17863px, 20px);
  margin-bottom: 0;
}
.values-sec .section-title {
  font-size: clamp(40px, 5vw, 70px);
}
@media screen and (max-width:1024px) {
  .values-sec .values-content {

    row-gap: 10px;
}
  .values-sec {
 
    row-gap: 20px;
    width: 100%;
    max-width: unset;
}
  .values-sec .section-title {
    font-size: 35px;
    margin-bottom: 0px;
    row-gap: 20px;
}
.values-sec .values-content .ist h1 {
  font-size: 25px;

}
.values-sec .values-content .ist div {

  row-gap: 10px;
}
.values-sec .values-content .ist p {
font-size: 17px;
line-height: 110%;
}
}
@media (max-width: 600px) {
  .values-sec .values-content .ist h1 {
    font-size: 20px;
  
  }
  .values-sec .values-content .ist p {
    font-size: 16px;
    line-height: 110%;
    }
  .values-sec {
    padding: 20px;
    row-gap: 20px;
  }
  .values-sec .section-title {
    font-size: 8vw;
    margin-bottom: 0px;
  }
  .values-sec .values-content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .values-sec .values-content .ist {
    display: flex;
    column-gap: 10px;
  }
  .values-sec .values-content .ist img {
    min-width: 40px !important;
    min-height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
  }
  .values-sec .values-content .ist div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
