.about-detail{
    padding-top:5%; 
    padding-bottom:5%;
    padding-left: 100px;
    padding-right: 100px;
    max-width: 1366px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
}

.about-detail .left{
    width: 60%;
    padding-right: 50px;
}
.about-detail .right{
    width: 40%;
}
.about-detail .right img{
    border-radius: 10px;
}
.about-detail .section-title{
margin-bottom: clamp(10px,6.6778vw + 8.71452px,20px);
}
.about-detail p{
    margin-bottom: 0;
    font-weight: 400px;
    line-height: clamp(28px,1.66945vw + 7.17863px,20px);
    font-size: clamp(18px,1.66945vw + 7.17863px,20px);
    width: 100%;
}
@media (max-width: 1199px){
.about-detail{
    padding-top:0%; 
}
    .about-detail h1{
        font-size: 40px;
    }
}
@media screen and (max-width:1024px) {

    .about-detail{
        padding:30px 50px;
        flex-direction: column;
        gap: 20px;
    }
    .about-detail .left{
        width: 100%;
        padding-right: 0;

    }
    .about-detail .right{
        width: 100%;

    }
    .about-detail p {
font-size: 18px;
line-height: 120%;
    }
    .about-detail .right img{
        width: 100%;
        object-fit: cover;
        height: 400px;

    }
    .values-sec{
        padding: 0px 50px;
    }
}
@media (max-width: 600px){
    .about-detail{
        padding:0px 20px 30px 20px;
        flex-direction: column;
        gap: 20px;
    }
    .about-detail .left{
        width: 100%;
        padding-right: 0;

    }
    .about-detail .right{
        width: 100%;
    }

    .about-detail .section-title {
            font-size: 8vw;
            margin-bottom: 10px;
        }
        .about-detail p{
            text-align: justify;
        }
    }

